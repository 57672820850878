import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from './modules/nutritionist.module.scss';
import {hasAnEdge } from '../../functions';

function Nutritionist({ data }) {
  const { allStaticNutritionistYaml } = data;
  const items = hasAnEdge(allStaticNutritionistYaml) ? allStaticNutritionistYaml.edges : [];
  console.log(data)
  return (
    <>
          <section className={styles.dieticianBox}>
            <h2 className={styles.title}>{items[0].node.dietician.title}</h2>
            <div className={styles.dietContainer}>
              {items[0].node.dietician.items.map((diet, index) => (
                <div key={index} className={styles.diet}>
                  <div className={styles.dietImage}>
                    <GatsbyImage image={diet.image.childImageSharp.gatsbyImageData} alt={diet.name} />
                  </div>
                  <div className={styles.textContainer}>
                    <h2 className={styles.dietName}>{diet.name}</h2>
                    <ul className={styles.tagsContainer}>
                    {diet.tags.map(tag=> (
                      <li className={styles.tag}>{tag.value}</li>
                    ))}
                    </ul>
                    <h3 className={styles.role}>{diet.role}</h3>
                    <p className={styles.description}>{diet.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </section>
    
          <section className={styles.specialization}>
            <p className={styles.specializationText}>{items[0].node.specialization.description}</p>
            <div className={styles.specializationImage}>
              <GatsbyImage image={items[0].node.specialization.image.childImageSharp.gatsbyImageData} alt={items[0].node.specialization.alt} />
            </div>
          </section>
    
          <section className={styles.mutuelle}>
            <h2 className={styles.mutuelleTitle}>{items[0].node.mutuelle.title}</h2>
            <p className={styles.mutuelleDescription} dangerouslySetInnerHTML={{ __html: items[0].node.mutuelle.description }} />
          </section>
        </>
  );
}

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <Nutritionist data={data} {...props} />}
      query={graphql`
        {
          allStaticNutritionistYaml {
            edges {
              node {
                dietician {
                  title
                  items {
                    name
                    image {
                      childImageSharp {
                        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                      }
                    }
                    tags {
                      value
                    }
                    role
                    description
                  }
                }

                specialization {
                  description
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                  }
                  alt
                }

                mutuelle {
                  title
                  description
                }
              }
            }
          }
        }
      `}
    />
  );
}
