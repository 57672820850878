import React from 'react';
import * as styles from './modules/dietaryCart.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';

const StarRating = ({ rating, star, starEmpty }) => {
  return (
    <>
      {[...Array(5)].map((_, index) => {
        const isFull = index + 1 <= rating; 
        // const isHalf = index + 0.5 === rating; 

        return (
          <img
            key={index}
            src={isFull ? star.publicURL : starEmpty.publicURL}
            alt="star"
            className={styles.containerStar}
          />
        );
      })}
    </>
  );
};


const DietaryCart = ({ choice, star, starEmpty}) => {
  const detail = (programName) => {
    navigate(`/product/${programName}`);
  };

  return (
    <div key={choice.id} className={styles.supplement}>
      <div>
        <div className={styles.center} onClick={() => detail(choice.program_type)}>
          {choice.title === 'Suivi Diététique' ? (
            <div className={styles.imageContainerDiet}>
              {choice.id && <p className={styles.supplementsTag}>{choice.id}</p>}
              <img src={choice.image.publicURL} alt={choice.alt_image}/>
            </div>
          ) : (
            <div className={styles.imageContainer}>
              {choice.id && <p className={styles.supplementsTag}>{choice.id}</p>}
              <img src={choice.image.publicURL} alt={choice.id}/>
            </div>
          )}
        </div>
        <h3 className={styles.supplementTitle} onClick={() => detail(choice.program_type)}>{choice.title}</h3>
        <p className={styles.supplementDescription} dangerouslySetInnerHTML={{ __html: choice.description }} />
      </div>
      <div className={styles.trustpilotButton}>
        <div className={styles.trustpilotContainer}>
          <StarRating rating={4} star={star} starEmpty={starEmpty}/>
          <a
            href="https://fr.trustpilot.com/review/bemum.co?utm_medium=trustbox&utm_source=MicroReviewCount"
            target="_blank"
            rel="noreferrer"
            className={styles.trustpilotText}
          >
            Avis Truspilot
          </a>
        </div>
        <button
          className={styles.button}
          dangerouslySetInnerHTML={{ __html: choice.cart }}
          onClick={() => detail(choice.program_type)}
        />
      </div>
    </div>
  );
};

export default DietaryCart;
