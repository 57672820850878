import React, { useEffect } from 'react';
import * as styles from './modules/headerPage.module.scss';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const HeaderPage = ({ title, description, subDescription}) => {
  return (
    <section className={styles.container}>
      <h1 className={styles.containerTitle} dangerouslySetInnerHTML={{ __html: title }} />
      <p className={styles.containerDescription} dangerouslySetInnerHTML={{ __html: description }} />
      {subDescription && (
        <p className={styles.containerSubDescription} dangerouslySetInnerHTML={{ __html: subDescription }} />
      )}
    </section>
  );
};

export default HeaderPage;
