import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import * as styles from '../modules/dietary-tracking.module.scss';
import CartContext from '../context/CartContext';
import { navigate } from 'gatsby';
import Link from '../components/utils/link';
import HeaderPage from '../components/repeatable/headerPage';
import DietaryCart from '../components/repeatable/dietaryCart';
import DietList from '../components/static/nutritionist'

const DietaryTracking = () => {
  useContext(CartContext);

  const {
    pageDietaryTrackingYaml: {
      title,
      description,
      seo,
      choices,
      nutrition_plus,
      star,
      star_empty,
    },
  } = useStaticQuery(graphql`
    query pageDietaryTrackingQuery {
      pageDietaryTrackingYaml {
        title
        description
        star {
          publicURL
        }
        star_empty {
          publicURL
        }
        seo {
          title
          meta
          canonical
        }

        choices {
          image {
            publicURL
          }
          alt_image
          title
          description
          cart
          program_type
        }

        nutrition_plus {
          title
          description
          start
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title={seo.title} description={seo.meta} canonical={seo.canonical} />
      <div className={styles.baseStyles}>
        <HeaderPage title={title} description={description} />
      </div>

      <section className={styles.programmeContainer}>
        <div className={styles.supplementsContainer}>
          {choices.map((choice) => (
            <DietaryCart choice={choice} star={star} starEmpty={star_empty}/>
          ))}
        </div>
      </section>

      <section className={styles.nutrition_plus}>
        <h2 className={styles.nutritionTitle} dangerouslySetInnerHTML={{ __html: nutrition_plus.title }} />
        <p className={styles.nutritionDescription} dangerouslySetInnerHTML={{ __html: nutrition_plus.description }} />
        <p className={styles.nutritionStart} dangerouslySetInnerHTML={{ __html: nutrition_plus.start }} />
      </section>

      <DietList />
    </Layout>
  );
};

export default DietaryTracking;
