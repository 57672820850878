// extracted by mini-css-extract-plugin
export var button = "dietaryCart-module--button--xWKYQ";
export var center = "dietaryCart-module--center--gVVL7";
export var containerStar = "dietaryCart-module--containerStar--pxg-Y";
export var imageContainer = "dietaryCart-module--imageContainer--MJatA";
export var imageContainerDiet = "dietaryCart-module--imageContainerDiet--y3TfL";
export var supplement = "dietaryCart-module--supplement--5G3Mq";
export var supplementDescription = "dietaryCart-module--supplementDescription--7pZyo";
export var supplementTitle = "dietaryCart-module--supplementTitle--R+PDc";
export var supplementsTag = "dietaryCart-module--supplementsTag--vQtNL";
export var trustpilotButton = "dietaryCart-module--trustpilotButton--TjwxY";
export var trustpilotContainer = "dietaryCart-module--trustpilotContainer--lQV7p";
export var trustpilotText = "dietaryCart-module--trustpilotText--Y-Rha";