// extracted by mini-css-extract-plugin
export var description = "nutritionist-module--description--yyDqD";
export var diet = "nutritionist-module--diet--zUpCh";
export var dietContainer = "nutritionist-module--dietContainer--lIavk";
export var dietImage = "nutritionist-module--dietImage--qbDD+";
export var dietName = "nutritionist-module--dietName--HT7Kk";
export var dieticianBox = "nutritionist-module--dieticianBox--37mWC";
export var mutuelle = "nutritionist-module--mutuelle--+SItR";
export var mutuelleDescription = "nutritionist-module--mutuelleDescription--R1FPA";
export var mutuelleTitle = "nutritionist-module--mutuelleTitle--3aCjj";
export var role = "nutritionist-module--role--JPb4R";
export var specialization = "nutritionist-module--specialization--UkG42";
export var specializationImage = "nutritionist-module--specializationImage--V4VCc";
export var specializationText = "nutritionist-module--specializationText--SMV4z";
export var tag = "nutritionist-module--tag--dtljI";
export var tagsContainer = "nutritionist-module--tagsContainer--kO8as";
export var textContainer = "nutritionist-module--textContainer--4p5-Q";
export var title = "nutritionist-module--title--OLRq0";